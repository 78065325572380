import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { MatDialogModule } from "@angular/material/dialog";
import {MatMenuModule} from '@angular/material/menu';
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatListModule } from "@angular/material/list";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MatTreeModule } from "@angular/material/tree";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PagesListComponent } from "./pages/pages-list/pages-list.component";
import { PagesFormComponent } from "./pages/pages-form/pages-form.component";
import { HttpClientModule } from "@angular/common/http";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { FaqCategoriesListComponent } from "./faq-categories/faq-categories-list/faq-categories-list.component";
import { FaqListComponent } from "./faq/faq-list/faq-list.component";
import { CasesTypesComponent } from "./cases-types/cases-types.component";
import { SaveCaseDialogComponent } from "./cases-types/save-case-dialog/save-case-dialog.component";
import { CaseQuestionsComponent } from "./cases-types/case-questions/case-questions.component";
import { SaveCaseSectionDialogComponent } from "./cases-types/save-case-section-dialog/save-case-section-dialog.component";
import { SaveCaseQuestionDialogComponent } from "./cases-types/save-case-question-dialog/save-case-question-dialog.component";
import { AdBoardsComponent } from './ad-boards/ad-boards.component';
import { AdComponent } from './ad-boards/ad/ad.component';


@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSortModule,
    MatGridListModule,
    MatToolbarModule,
    MatRadioModule,
    MatSelectModule,
    MatListModule,
    TranslateModule,
    MatTreeModule,
    HttpClientModule,
    AngularEditorModule,
    DragDropModule,
    MatTooltipModule,
    MatMenuModule
  ],
  declarations: [
    PagesListComponent,
    PagesFormComponent,
    FaqCategoriesListComponent,
    FaqListComponent,
    CasesTypesComponent,
    SaveCaseDialogComponent,
    CaseQuestionsComponent,
    SaveCaseSectionDialogComponent,
    SaveCaseQuestionDialogComponent,
    AdBoardsComponent,
    AdComponent,
  ],
  exports: [
    PagesListComponent,
    PagesFormComponent,
    FaqCategoriesListComponent,
    FaqListComponent,
  ],
  entryComponents: [],
})
export class CmsModule {}
