import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { AdsService } from "../../services/ads.service";
import { Ad } from "src/app/shared/models/interfaces";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-ad",
  templateUrl: "./ad.component.html",
  styleUrls: ["./ad.component.scss"],
})
export class AdComponent implements OnInit, OnDestroy {
  @Input() isNewAd!: boolean;
  @Input() adInfo!: Ad;
  @Input() adIndex!: number;
  @Output() isAddingNewAd = new EventEmitter<boolean>();
  @Output() deleteAdId = new EventEmitter<number>();
  @Output() newAd = new EventEmitter<Ad>();

  isEditing: boolean = false;

  previewUrls: (string | null)[] = [null, null];
  errorMessages: (string | null)[] = [null, null];
  selectedFiles: (File | null)[] = [null, null];
  areBothImagesValid = false;

  isSubmitting: boolean = false;
  isLoadingWeb: boolean = false;
  isLoadingMob: boolean = false;

  dimensionsWeb = {
    width: 1198,
    height: 176,
  };

  dimensionsMobile = {
    width: 328,
    height: 136,
  };

  adSub$!: Subscription;

  @ViewChild("imageInputWeb") imageInputWeb!: ElementRef<HTMLInputElement>;
  @ViewChild("imageInputMobile") imageInputMobile!: ElementRef<HTMLInputElement>;

  constructor(
    private _AdsService: AdsService,
    private translate: TranslateService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  triggerFileInputClick(uploaderInpt: string): void {
    if (uploaderInpt === "web") {
      this.imageInputWeb.nativeElement.click();
    } else if (uploaderInpt === "mobile") {
      this.imageInputMobile.nativeElement.click();
    }
  }

  onFileSelected(event: Event, uploaderIndex: number): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        this.previewUrls[uploaderIndex - 1] = e.target?.result as string;
        img.src = this.previewUrls[uploaderIndex - 1]!;
      };

      const dimensions =
        uploaderIndex === 1 ? this.dimensionsWeb : this.dimensionsMobile;

      img.onload = () => {
        if (
          img.width === dimensions.width &&
          img.height === dimensions.height
        ) {
          this.errorMessages[uploaderIndex - 1] = null;
          this.selectedFiles[uploaderIndex - 1] = file;

          if (!this.isNewAd) {
            this.editAd(uploaderIndex - 1);
          }

        } else {
          this.previewUrls[uploaderIndex - 1] = null;
          this.errorMessages[
            uploaderIndex - 1
          ] = `Invalid dimensions: ${img.width}x${img.height}. Expected ${dimensions.width}x${dimensions.height}.`;
          this.selectedFiles[uploaderIndex - 1] = null;
        }
        this.checkImagesValidity();
      };

      img.onerror = () => {
        this.previewUrls[uploaderIndex - 1] = null;
        this.errorMessages[uploaderIndex - 1] = "Invalid image file.";
        this.selectedFiles[uploaderIndex - 1] = null;
        this.checkImagesValidity();
      };

      reader.readAsDataURL(file);
    }
  }

  checkImagesValidity(): void {
    this.areBothImagesValid = this.selectedFiles.every((file) => file !== null);
  }

  submitImages(): void {
    if (this.areBothImagesValid) {
      this.isSubmitting = true;
      this.isLoadingWeb = true;
      this.isLoadingMob = true;

      const formData = new FormData();
      formData.append("image1", this.selectedFiles[0]!);
      formData.append("image2", this.selectedFiles[1]!);

      this.adSub$ = this._AdsService.createAd(formData).subscribe({
        next: (data: any) => {
          this.isSubmitting = false;
          this.isLoadingWeb = false;
          this.isLoadingMob = false;
          this.newAd.emit(data);
        },
        complete: () => {
          this.isAddingNewAd.emit(false);
        },
      });
    }
  }

  editAd(index: number) {
    this.isSubmitting = true;
    this.isEditing = true;
    const formData = new FormData();
    if (index === 0) {
      this.isLoadingWeb = true;
      formData.append("desktopImageUrl", this.selectedFiles[0]!);
      formData.append("mobileImageUrl", this.adInfo.mobileimageUrl!);
    } else if (index === 1) {
      this.isLoadingMob = true;
      formData.append("desktopImageUrl", this.adInfo.desktopImageUrl!);
      formData.append("mobileImageUrl", this.selectedFiles[1]!);
    }

    this.adSub$ = this._AdsService.editAd(this.adInfo.id, formData).subscribe({
      next: (res: any) => {
        this._AdsService.getAdById(this.adInfo.id).subscribe({
          next: (data: any) => {
            this.adInfo = data;

            this.selectedFiles = [null, null];
            this.previewUrls = [null, null];

            this.isSubmitting = false;
            this.isLoadingWeb = false;
            this.isLoadingMob = false;
          },
        });
      },
    });
  }

  removeImage(index: number) {
    if (this.isNewAd) {
      this.selectedFiles[index] = null;
      this.previewUrls[index] = null;
      this.checkImagesValidity();
    } else {
      if (index === 0) {
        this.adInfo.desktopImageUrl = "";
      } else if (index === 1) {
        this.adInfo.mobileimageUrl = "";
      }
    }
  }

  cancelNewAd() {
    this.selectedFiles = [null, null];
    this.previewUrls = [null, null];
    this.errorMessages = [null, null];
    this.isAddingNewAd.emit(false);
  }

  openDeleteCaseDialog(adId: number) {
    this._dialog
      .open(ConfirmDialogComponent, {
        direction: this.translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "confirm-dialog",
        data: {
          content: this.translate.instant("confirm-dialog.confoirm-delete-ad"),
          icon: "delete_forever",
        },
      })
      .afterClosed()
      .subscribe(async (isDeleted) => {
        if (isDeleted) {
          try {
            await this._AdsService.deleteAd(adId).toPromise();
            this.deleteAdId.emit(adId);
          } catch (err) {}
        }
      });
  }

  ngOnDestroy(): void {
    this.adSub$?.unsubscribe();
  }
}
