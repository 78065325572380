import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { getHeadersWithToken } from "src/app/shared/helpers/getHeadersWithToken";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AdsService {
  apiUrl = environment.apiHost + "admin/ads";

  constructor(private _http: HttpClient) {}

  listAds() {
    return this._http.get(this.apiUrl, { headers: getHeadersWithToken() });
  }

  getAdById(id: number){
    return this._http.get(`${this.apiUrl}/${id}`, { headers: getHeadersWithToken() });
  }

  createAd(adFiles: FormData) {
    return this._http.post(this.apiUrl, adFiles, {
      headers: getHeadersWithToken(),
    });
  }

  editAd(id: number, adFiles: FormData) {
    return this._http.patch(`${this.apiUrl}/${id}`, adFiles, {
      headers: getHeadersWithToken(),
    });
  }

  deleteAd(id: number) {
    return this._http.delete(`${this.apiUrl}/${id}`, {
      headers: getHeadersWithToken(),
    });
  }
}
