import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AdsService } from "../services/ads.service";
import { Subscription } from "rxjs";
import { Ad } from "src/app/shared/models/interfaces";
import { DataService } from "src/app/shared/services/data.service";

@Component({
  selector: "app-ad-boards",
  templateUrl: "./ad-boards.component.html",
  styleUrls: ["./ad-boards.component.scss"],
})
export class AdBoardsComponent implements OnInit, OnDestroy {
  adsList!: Ad[];
  isAddingNewAd: boolean = false;
  adSub$!: Subscription;
  @ViewChild('newAdElement') newAdElement!: ElementRef;

  constructor(private _AdsService: AdsService, private dataService: DataService) {}

  ngOnInit(): void {
    this.adSub$ = this._AdsService.listAds().subscribe({
      next: (list: any) => {
        this.adsList = list;
      },
    });
  }

  addNewBoardDialog() {
    if (this.adsList.length < 5) {
      this.isAddingNewAd = true;
      setTimeout(() => {
        this.newAdElement.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  }

  cancelNewAd(event: any) {
    if (!event) {
      this.isAddingNewAd = false;
    }
  }

  addNewAd(ad: Ad){
    this.adsList.push(ad);
    this.dataService.notification("تم إضافة اللوحة بنجاح", 'success-snackbar');
  }

  deleteAd(adId: number) {
    this.adsList = this.adsList.filter((adItem) => adItem.id != adId);
    this.dataService.notification("تم حذف اللوحة بنجاح", 'success-snackbar');
  }

  ngOnDestroy(): void {
    this.adSub$?.unsubscribe();
  }
}
