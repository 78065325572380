import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "../auth/login/login.component";
import { LogoutComponent } from "../auth/logout/logout.component";
import { HomeComponent } from "../home/home/home.component";
import { AuthGuard } from "./auth.guard";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PagesListComponent } from "../cms/pages/pages-list/pages-list.component";
import { PagesFormComponent } from "../cms/pages/pages-form/pages-form.component";
import { FaqCategoriesListComponent } from "../cms/faq-categories/faq-categories-list/faq-categories-list.component";
import { FaqListComponent } from "../cms/faq/faq-list/faq-list.component";
import { UserListComponent } from "../users/user-list/user-list.component";
import { UserCasesListComponent } from "../user-cases/user-cases-list/user-cases-list.component";
import { CasesTypesComponent } from "../cms/cases-types/cases-types.component";
import { CaseQuestionsComponent } from "../cms/cases-types/case-questions/case-questions.component";
import { ReportsComponent } from "../reports/reports/reports.component";
import { UserRoles } from "../shared/models/interfaces";
import { SaveUserComponent } from "../users/save-user/save-user.component";
import { UsercasesStatusReportsComponent } from "../reports/usercases-status-reports/usercases-status-reports.component";
import { LawyersReportsComponent } from "../reports/lawyers-reports/lawyers-reports.component";
import { ConciliatorReportsComponent } from "../reports/conciliator-reports/conciliator-reports.component";
import { UsercasesTypesReportsComponent } from "../reports/usercases-types-reports/usercases-types-reports.component";
import { PaymentsComponent } from "../payments/payments/payments.component";
import { EvaluationsReportComponent } from "../reports/evaluations-report/evaluations-report.component";
import { ReasonsCasesReportsComponent } from "../reports/reasons-cases-reports/reasons-cases-reports.component";
import { ServiceTypePageComponent } from "../services-types/pages/service-type-page/service-type-page.component";
import { ServicePlanPageComponent } from "../services-types/pages/service-plan-page/service-plan-page.component";
import { PromoCodePageComponent } from "../services-types/pages/promo-code-page/promo-code-page.component";
import { EvaluationsInquiriesReportComponent } from "../reports/evaluations-inquiries-report/evaluations-inquiries-report.component";
import { AdBoardsComponent } from "../cms/ad-boards/ad-boards.component";

const coreRoutes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  //{path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  { path: "home", component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: "cases-types",
    component: CasesTypesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "cases/question/:caseId",
    component: CaseQuestionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "faqCategories",
    component: FaqCategoriesListComponent,
    canActivate: [AuthGuard],
  },
  { path: "faq", component: FaqListComponent, canActivate: [AuthGuard] },
  {
    path: "user-management",
    component: UserListComponent,
    canActivate: [AuthGuard],
    data: { state: UserRoles.user },
  },
  {
    path: "supervisor-management",
    component: UserListComponent,
    canActivate: [AuthGuard],
    data: { state: UserRoles.supervisor },
  },
  {
    path: "lawyer-management",
    component: UserListComponent,
    canActivate: [AuthGuard],
    data: { state: UserRoles.lawyer },
  },
  {
    path: "conciliator-management",
    component: UserListComponent,
    canActivate: [AuthGuard],
    data: { state: UserRoles.conciliator },
  },
  {
    path: "call-center-management",
    component: UserListComponent,
    canActivate: [AuthGuard],
    data: { state: UserRoles.callCenter },
  },
  {
    path: "new-user/:userRole",
    component: SaveUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "show-user/:userRole/:userId",
    component: SaveUserComponent,
    canActivate: [AuthGuard],
  },
  { path: "pages", component: PagesListComponent, canActivate: [AuthGuard] },
  {
    path: "pages/form",
    component: PagesFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "pages/form/:id",
    component: PagesFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "user-cases",
    component: UserCasesListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "cases-status-reports",
    component: UsercasesStatusReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "cases-types-reports",
    component: UsercasesTypesReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "lawyers-reports",
    component: LawyersReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "conciliator-reports",
    component: ConciliatorReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "evaluations-cases-reports",
    component: EvaluationsReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "evaluations-inquiries-reports",
    component: EvaluationsInquiriesReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reasons-cases-reports",
    component: ReasonsCasesReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "payments",
    component: PaymentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "services",
    component: ServiceTypePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "services/plans/:serviceId",
    component: ServicePlanPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "services/promo-code",
    component: PromoCodePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "ad-boards",
    component: AdBoardsComponent,
    canActivate: [AuthGuard],
  },
  { path: "**", component: PageNotFoundComponent },
];

export const CoreRouting: ModuleWithProviders<unknown> =
  RouterModule.forChild(coreRoutes);
